import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { UqCustomerProfile, UqCustomerStory, UqRequestDemoBanner, UqLink, UqTypography, UqContainerV2, UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2, UqArticleQuote, UqPage } from '@uq-components';
import Logo from '@uq-assets/logos/styleseat.svg';

import * as styles from './styleseat.module.scss';

export default function StyleseatPage() {
  return (
    <UqPage
      config={{
        seo: {
          description: 'Read StyleSeat\'s story to see how they\'ve leveraged unitQ to increase their user rentention by 100%.',
          src: '/images/v2/customers/styleseat/styleseat-hero.png',
          title: 'StyleSeat Customer Story',
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqCustomerStory>
          <UqCustomerStory.Header
            backgroundSrc="/images/v2/customers/styleseat/styleseat-hero.png"
            classes={{ background: styles.hero }}
          >
            <UqTypography as="h1">
              {'StyleSeat + unitQ: <br /> Feedback matters: Building products that delight users'}
            </UqTypography>
          </UqCustomerStory.Header>

          <UqCustomerStory.Content>
            <UqCustomerProfile logo={<Logo />}>
              <UqCustomerProfile.Section title="Product">
                Online booking platform for beauty and wellness professionals
              </UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Headquarters">San Francisco, CA</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Employees">150+</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Quality Challenge">
                Manual processes, too many feedback sources, feature prioritization issues, team silos
              </UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Results">
                <ul>
                  <li>
                    <strong>18% improvement</strong> in the unitQ Score
                  </li>
                  <li>
                    <strong>35% boost</strong> in App Store and Google Play ratings
                  </li>
                  <li>
                    <strong>100% increase</strong> in user retention
                  </li>
                  <li>
                    <strong>50% decrease</strong> in user churn
                  </li>
                  <li>
                    <strong>Single source of truth</strong> for prioritization and user feedback
                  </li>
                </ul>

                <UqArticleQuote
                  author={{
                    name: 'Greg Burch',
                    role: 'VP of Engineering, StyleSeat',
                  }}
                >
                  unitQ Monitor is 100% necessary to our StyleSeat. It
                  gives us a clear understanding of product quality in
                  real-time and has significantly reduced the amount of
                  time we spend troubleshooting issues. We absolutely need
                  it! We have gone from satisfied users to delighted users.
                </UqArticleQuote>
              </UqCustomerProfile.Section>
            </UqCustomerProfile>

            <UqCustomerStory.Article>
              <p>
                <UqLink
                  className="article-link"
                  to="https://styleseat.com"
                  external
                  hideArrow
                >
                  Styleseat
                </UqLink>
                <span>
                  {' '}is an online booking and payment platform used by beauty
                  and wellness professionals and their clients around the
                  United States. These professionals can showcase their
                  work, connect with new and existing clients, and build
                  their business. On the client-side, they can discover new
                  services and providers, book appointments online, and get
                  inspired. Since launching in 2011, StyleSeat has powered
                  120 million appointments worth $6.5B.
                </span>
              </p>
              <p>
                The company is keenly aware of the impact that product
                quality has on retention, brand equity, and revenue. It&apos;s
                why StyleSeat is laser-focused on achieving the highest
                levels of user satisfaction and driving positive change
                across product delivery.
              </p>

              <UqTypography as="h4">
                The Situation: !-Teams working in silos, data getting lost-!
              </UqTypography>

              <p>
                With a consistent 3.5 star rating from their professionals
                and clients, StyleSeat knew their users were satisfied but
                not experiencing the wow factor they wanted to deliver.
                The company&apos;s goal was to elevate the product to a level
                that could earn true user delight and retention. The
                problem was that the engineering team was challenged with
                getting the critical user feedback data they needed to
                drive feature prioritization and product quality.
              </p>
              <p>
                With feedback coming from multiple sources: App Store
                reviews, Play Store reviews, Zendesk support tickets and
                more - key feedback data was getting lost and there was no
                single source of truth or ownership of the data. There
                were too many tools holding data, and too many departments
                - support, QA, product, engineering - working in silos and
                not toward a common goal.
              </p>
              <p>
                What evolved is what Greg Burch, VP of Engineering at
                StyleSeat, referred to as papercut syndrome. Teams were
                fixing bugs, but not driving the next big feature.
                StyleSeat set out to find and develop the enhancements
                that could earn the five stars rating they knew was possible.
              </p>

              <UqTypography as="h4">
                The Solution: !-Automated user feedback platform-!
              </UqTypography>

              <p>
                StyleSeat chose the automated user feedback platform from
                unitQ that could deliver the critical feedback data and
                internal team focus they hadn&apos;t had before. The powerful
                solution, unitQ Monitor, allows them to experiment with
                new features and easily measure outcomes with real-time
                user feedback and analysis. For example, when StyleSeat
                started working on the appointment power-booking feature,
                they could launch it and immediately hunt for all users
                talking about it. They are able to drill down to find any
                users having problems with booking appointments and why,
                from both the booker and professional taking the
                appointment. This type of real-time feedback has proved
                invaluable for StyleSeat.
              </p>

              <UqArticleQuote
                author={{
                  name: 'Greg Burch',
                  role: 'VP of Engineering, StyleSeat',
                }}
              >
                We realized that unitQ can turn our existing user base
                into an extended quality team. Before unitQ, our product
                improvement prioritization started with a long meeting
                and many opinions. Now, we look at unitQ Monitor and
                immediately start doing what we&apos;re good at: Building a
                great product.
              </UqArticleQuote>

              <UqTypography as="h4">
                Product quality as the single source of truth
              </UqTypography>

              <p>
                The unitQ score has become a company-wide performance
                metric, with teams now aligned on quality enterprise-wide
                and working toward a common goal and OKRs. In addition,
                now that StyleSeat maintains all the user feedback in a
                centralized report, teams get the whole picture when it
                comes to product issues and roadmaps. They gain the
                insights they need to prioritize features and bugs and can
                build the solution that keeps their professionals and
                clients happy and retained.
              </p>

              <UqTypography as="h4">Actionable user feedback</UqTypography>

              <p>
                StyleSeat is also using unitQ Monitor to help teams find
                and fix product issues as they&apos;re being reported, by
                piping in user feedback from any support ticketing
                channel, review site, or language - all in real-time. The
                automatic tagging and reporting features provide a rich
                context to ensure accuracy and the ability to reproduce.
                It brings granularity to the data to produce crisp
                signals, making bugs easier to tackle and prioritize.
              </p>

              <UqTypography as="h4">
                {'The Results: !-Teams empowered, driving toward a common goal-!'}
              </UqTypography>

              <p>
                The StyleSeat team is now working together smarter and
                faster, focused on a common goal of building and
                delivering a great product for their professionals and
                clients. unitQ has empowered their employees and teams to
                use product quality metrics as a common language and break
                through the noise once hindering their jobs. It brings a
                sense of unity and collaboration that previously didn’t
                exist.
              </p>

              <UqTypography as="h4">The Numbers</UqTypography>

              <p>
                StyleSeat found the wow factor, and users noticed the
                improvements as well - raising their App Store ratings
                from 3.5 to 4.9 and Play Store ratings from 3.5 to 4.6. In
                just 4 months, their unitQ Score - the Quality Metric,
                improved by 18%, from 77 up to 91. This combination of
                team empowerment, quantified insights, and noise reduction
                makes unitQ a key driver in user satisfaction.
              </p>

              <StaticImage
                src="/images/v2/customers/styleseat-growth-chart.png"
                alt="Styleseat growth chart"
              />

              <UqArticleQuote
                author={{
                  name: 'Greg Burch',
                  role: 'VP of Engineering, StyleSeat',
                }}
              >
                unitQ Monitor is 100% necessary to our StyleSeat. It
                gives us a clear understanding of product quality in
                real-time and has significantly reduced the amount of
                time we spend troubleshooting issues. We absolutely need
                it! We have gone from satisfied users to delighted users.
              </UqArticleQuote>
            </UqCustomerStory.Article>
          </UqCustomerStory.Content>
        </UqCustomerStory>

        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}
